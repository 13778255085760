import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import H1 from "../../components/h1"
import Button from "../../components/button"
import ContentPage from "../../layouts/contentPage"
import ContentPageWrapper from "../../layouts/contentPageWrapper"
import ContentPageBg from "../../layouts/contentPageBg"
import LinkList from "../../components/linkList";
import InstaCard from "../../components/instaCard";
import Video from "../../video/bubbles.mp4"

// import PrivacyPolicy from "../static/regulamin/Polityka_prywatnosci_pl.pdf";
// import CookiesPolicy from "../static/regulamin/Polityka_plikow_cookies_pl.pdf";
// import Policy from "../static/regulamin/Regulamin_pl.pdf";

const Contact = () => (
    <Layout lang={'EN'}>
        <Seo title="Contact" lang={'EN'}/>
        <ContentPage>
            <ContentPageWrapper>
                <p>Contact Us:</p>
                <H1 className="h1--contact"><a href="mailto:kontakt@oxygenetic.pl">kontakt@oxygenetic.pl</a></H1>
                <InstaCard lang={'EN'} />
                <p>Learn about:</p>
                <LinkList>
                    <a href="/en/privacy-policy" target="_blank">Privacy Policy</a>
                </LinkList>
                <Button as={Link} to="/en/" primary="true" margin="4rem 0 0 0">Homepage</Button>
            </ContentPageWrapper>
            <ContentPageBg>
                <video autoPlay muted loop>
                    <source src={Video} type="video/mp4" />
                </video>
                {/* <StaticImage
              src="../images/media/contact/contact-bg.png"
              loading="eager"
              width={440}
              quality={95}
              placeholder="blurred"
              className="offset"
              formats={["auto", "webp", "avif"]}
              alt="oxygenetic"
          /> */}
            </ContentPageBg>
        </ContentPage>
    </Layout>
)

export default Contact
